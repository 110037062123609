// useFormValidation.js
import { useState, useCallback } from "react";

const useFormValidation = () => {
  const [errors, setErrors] = useState({});

  const validateField = useCallback((field, value) => {
    let error = "";

    const prettyName = {
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      phone: "Phone",
      address: "Address",
      city: "City",
      state: "State",
      zipcode: "Zip Code",
      age: "Age",
    };

    // Add validation rules here
    if (!value) {
      error = `${prettyName[field]} is required.`;
    } else if (field === "email" && !/\S+@\S+\.\S+/.test(value)) {
      error = "Email is invalid.";
    }

    // Update the errors state
    setErrors((prevErrors) => ({ ...prevErrors, [field]: error }));

    return error; // Return the error to check if it's empty or not
  }, []);

  const clearErrors = useCallback(() => {
    setErrors({});
  }, []);

  return { errors, validateField, clearErrors };
};

export default useFormValidation;
